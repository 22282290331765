<template>
	<FloatActions :rightAlign="true" :responsive="{ bp1000: 0.8 }" />
	<div class="content-pad narrow">
		<NavSub :routed="true" :items="navItems" />
		<router-view />
	</div>
</template>

<script>
// Components
import FloatActions from '@/components/FloatActions'
import NavSub from '@/components/NavSub'

export default {
	name: 'GeneralMain',
	components: {
		FloatActions,
		NavSub,
	},
	data() {
		return {
			navItems: [
				{
					display: 'Home',
					to: { name: 'Home' },
				},
				{
					display: 'About',
					to: { name: 'AboutTemp' },
				},
				{
					display: 'Donate',
					to: { name: 'Donate' },
				},
				{
					display: 'Contact',
					to: { name: 'Contact' },
				},
			],
		}
	},
}
</script>

<style lang="scss" scoped>
.content-pad {
	margin-bottom: 1rem;
}
</style>
